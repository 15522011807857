.sideBarPaper {
  flex-shrink: 0;
  overflow-x: hidden;
  width: 412px;
}

@media (max-width: 600px) {
  .sideBarPaper {
    width: 100%;
  }
}
