.modalPaper {
  border-radius: 5px;
  min-width: 288px;
  max-width: 288px;
}

.logo {
  width: 120px;
}

.icon {
  margin-right: 12px;
  margin-left: 0;
  color: #686868;
}

.modalDialog {
  padding-top: 0;
}

.modalContent {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 4px !important;
}

.loginButton {
  margin-bottom: 16px;
  box-shadow: none;
}

.errorMessage {
  border: 1px solid #d50000;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 7px;
  color: #d50000;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
