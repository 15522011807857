.item {
  width: 412px;
  height: 100%;
}

@media (max-width: 600px) {
  .item {
    width: 100%;
  }
}

.hidden {
  display: none;
}

.button {
  text-transform: none;
}

.toolsContainer {
  display: flex;
  padding-top: 16px;
}

.toolsButton {
  text-transform: none;
}

.toolsSpacer {
  flex-grow: 1;
}

.fixedHeader {
  position: fixed;
  margin-top: 0;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: inherit;
}

@media (max-width: 600px) {
  .fixedHeader {
    width: 100%;
  }
}

.listWrapper {
  margin-top: 119px;
}

@media (max-width: 600px) {
  .listWrapper {
    width: 100%;
  }
}
