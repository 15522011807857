.modalPaper {
  border-radius: 5px;
  width: 360px;
}

@media (max-width: 600px) {
  .modalPaper {
    width: 100%;
    border-radius: 0;
  }
}

.modalActions {
  padding: 8px 16px;
}

.modalContent {
  padding: 22px 24px;
}

.modalContent:first-child {
  padding-top: 22px;
}
