.reportTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 72px;
  line-height: 24px;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  padding-bottom: 4px;
}

.reportSubTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 48px;
  width: 40%;
  line-height: 24px;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  text-transform: uppercase;
}

.titlesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 40%;
}

.tooltipWrapper {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.44);
  position: absolute;
  left: 0;
  z-index: 999;
}

.dragHandleGroupWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.noContainersTitle {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.responsiveGridLayoutWrapper {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
}
