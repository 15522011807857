.table {
  background-color: #2780e3;
}

.tableCellHead {
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: none;
  font-size: 16px;
  font-family: 'Roboto-Medium', sans-serif;
}

.tableCell {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: none;
  font-size: 16px;
  font-family: 'Roboto-Regular', sans-serif;
}
