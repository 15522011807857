.tabButton {
  min-width: auto;
  flex-basis: auto;
  padding-inline: 16px;
}

.tabs {
  padding: 0;
  position: sticky;
  top: 68px;
  z-index: 2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.tabsHidden {
  min-height: 0;
  transition: 0.5s;
}

.tabPanel {
  margin-top: 0;
}
