.buttonContainer {
  margin-left: 16px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
}

.button {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
}

.modalOverlay {
  width: 412px;
  display: grid;
  gap: 12px;
  padding: 12px 0;
  grid-template-columns: 1fr 1fr 1fr;
}
