.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  position: relative;
  border-radius: 2px;
}

.titleContainer {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: none;
  text-align: center;
}

.titleWrapper {
  width: 100%;
  padding: 0 8px;
  text-align: center;
}

.tableWrapper {
  width: 100%;
}

.table {
  background-color: #2780e3;
}

.menuIcon {
  height: 20px;
  position: absolute;
}

.menuIconRotated {
  height: 20px;
  position: absolute;
  transform: rotate(180deg);
}

.tableCellHead {
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: none;
  font-size: 16px;
  padding-right: 16px;
  position: relative;
  font-family: 'Roboto-Medium', sans-serif;
  text-align: right;
}

.tableCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: none;
  font-size: 16px;
  font-family: 'Roboto-Regular', sans-serif;
}
