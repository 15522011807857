.groupContainer {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.buttonsWrapper {
  padding: 4px;
}

.iconButton {
  background-color: rgba(0, 0, 0, 0.2);;
  height: 40px;
  width: 40px;
  color: #ffffff;
  margin-right: 4px;
}

.iconButton:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
