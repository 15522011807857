.modalPaper {
  border-radius: 5px;
  position: relative;
  width: 540px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

@media (max-width: 600px) {
  .modalPaper {
    width: 100%;
    border-radius: 0;
  }
}

.modalTitle {
  padding: 16px;
}

.modalContent {
  padding: 16px;
}

.modalActions {
  padding: 14px 16px;
}
