.listSubheader {
  margin-top: 15px;
  margin-bottom: 10px;
}

.itemToHideOrShow {
  visibility: hidden;
  opacity: 1;
  transition: opacity 0.2s linear;
}

@media (max-width: 600px) {
  .itemToHideOrShow {
    visibility: visible;
    opacity: 1;
  }

  .listItemWrapper:hover .itemToHideOrShow {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear;
  }
}

.listItem {
  min-height: 56px;
}

.listItemWrapper {
  height: 48px;
  padding-right: 48px;
}

.listItemWrapper:hover .itemToHideOrShow {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.accessTimeIcon {
  color: rgba(255, 255, 255, 0);
}

.objectTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 48px;
  line-height: 24px;
}
