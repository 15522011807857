.button {
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  line-height: normal;
  height: 100%;
  border-width: 1px;
  border-style: solid;
}
