.header {
  padding: 4px 4px 4px 0;
  margin-left: 8px;
}
.backButton {
  margin-right: 16px;
}
.name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
}
