.root {
    position: fixed;
    top: 0;
    width: 411px;
    padding-top: 0;
    height: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
}

@media (max-width: 600px) {
    .root {
        width: 100%;
    }
}

.content {
    height: 100%;
    overflow-y: auto;
}

.empty {
    text-align: center;
}

.headerTitle {
    width: 100%;
}
