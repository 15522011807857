.formContainer {
    position: relative;
    max-height: 200px;
    overflow: hidden;
}

.iconButton {
    background-color: #000000;
    opacity: 0.44;
    height: 40px;
    width: 40px;
    color: #ffffff;
}
.iconButton:hover {
    background-color: #000000;
    opacity: 0.6;
}

.imageBox {
    min-height: 120px;
    height: 150px;
    max-height: 200px;
}

.errorMessage {
    color: #ff0000;
    margin-top: 8px;
    font-size: 12px;
    text-align: right;
}

.toolsContainer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 16px 0 8px;
    top: 8px;
}

.toolsContainerRight {
    display: flex;
    gap: 16px;
    align-items: center;
}
