.searchBar {
  display: flex;
  flex: 1;
  padding: 0;
  align-items: center;
  margin-top: 8px;
  margin-right: 0;
}

.atfOutlinedInput:not(:hover):not(.atfFocused) .atfNotchedOutline {
  border-color: transparent;
}

.atfOutlinedInput:hover:not(.atfFocused) .atfNotchedOutline {
  border-color: transparent;
}

.atfOutlinedInput.atfFocused .atfNotchedOutline {
  border: transparent;
}

.inputRoot {
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
