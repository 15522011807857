.wrapper {
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 2px;
  top: 2px;
  right: 0;
  bottom: 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  pointer-events: auto;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 2px;
}

.iconButton {
  padding: 0;
  pointer-events: auto;
}
