.root {
    border-radius: 5px;
    padding: 0 !important;
    height: 32px;
}

.endAdornment {
    right: 2px !important;
}

.input {
    font-size: 13px;
    padding: 1px 20px 1px 8px !important;
}

.root button {
    color: inherit;
}

.popper {
    width: fit-content !important;
    margin-top: 8px !important;
}

.listbox {
    max-height: 320px;
}

.paper {
    width: 100%;
    max-width: 500px;
}