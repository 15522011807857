.modalDialog {
    padding-top: 0
}
.info {
    width: 412px;
    padding: 8px 16px;
}
.modalContent {
    padding: 0;
}
.listItemIcon {
    min-width: 40px
}
.menu {
    width: 412px
}

.avatar {
    font-size: 18px;
    background-color: black;
    color: white;
    box-shadow: none;
}

.offset {
    margin-left: 16px;
}

.pointer-auto {
    pointer-events: auto;
}

.pointer-none {
    pointer-events: none;
}

.h-full {
    height: 100%;
}

.loadingContainer {
    color: #fff;
    margin-top: 6px;
    position: absolute;
}