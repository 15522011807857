.root {
  margin-right: 8px;
  filter: brightness(0.5);
  width: 30px;
  height: 30px;
}

.icon {
  right: 30px;
}

.selectSelect {
  padding-left: 0;
}

.img {
  object-fit: contain;
}
