.wrapper {
  position: relative;
  padding-right: 3px;
  padding-left: 3px;
}

.groupWrapper {
  position: relative;
}

.dashboardTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 72px;
  width: 40%;
  line-height: 24px;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
}
