.content {
  overflow: auto;
  flex-grow: 1;
  margin-left: 0;
}

@media (max-width: 600px) {
  .content {
    margin-left: 0;
    border: none;
  }

  .contentShift {
    margin-left: 0;
  }
}
