
.itemToHideOrShow {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear;
}

@media (max-width: 600px) {
    .itemToHideOrShow {
        visibility: visible;
        opacity: 1;
    }
}

.itemToHover:hover .itemToHideOrShow {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear;
}

.title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}