.iconButton {
  background-color: #000000;
  opacity: 0.44;
  height: 40px;
  width: 40px;
  color: #ffffff;
  margin-left: 16px;
}
.iconButton:hover {
  background-color: #000000;
  opacity: 0.6;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}