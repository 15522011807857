.statusContainer {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
}

.statusLabel {
  display: flex;
  align-items: center;
}