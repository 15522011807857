.commonHeader {
  display: flex;
  flex: 1;
  align-items: center;
}

.starButton {
  margin-right: 12px;
  display: none;
}

.boxContainer {
  display: flex;
  align-items: center;
  padding-right: 6px;
}
