.item1ToHideOrShow {
  display: block;
}

.checkbox {
  margin-left: -5px;
  margin-right: 9px;
  padding-right: 14px;
}

.hidden {
  display: none;
}

.item2ToHideOrShow {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s linear;
}

@media (max-width: 600px) {
  .item2ToHideOrShow {
    visibility: visible;
    opacity: 1;
  }
}

.link {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
}

.link:focus,
.link:hover,
.link:visited,
.link:link,
.link:active {
  text-decoration: none;
}

.item3ToHideOrShow {
  display: none;
}

.item3ToShow {
  display: block;
}

.itemToHover:hover .item1ToHideOrShow {
  visibility: hidden;
  display: none;
}

.itemToHover:hover .item2ToHideOrShow {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.itemToHover:hover .item3ToHideOrShow {
  visibility: visible;
  display: block;
}
