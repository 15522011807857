.switcherSmallSize > svg {
  width: 72px;
  height: 72px;
}

.switcherMediumSize > svg {
  width: 96px;
  height: 96px;
}

.wrapper {
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  align-items: center;
}

.wrapperMain {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  overflow: hidden;
  height: 100%;
  padding-right: 8px;
  padding-left: 8px;
}

.fabSmall {
  width: 45px;
  height: 45px;
}

.fabMedium {
  width: 90px;
  height: 90px;
}

.forwardIconSmall {
  width: 35px;
  height: 35px;
}

.forwardIconMedium {
  width: 70px;
  height: 70px;
}

.switcherWrapper {
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-grow: 1;
}

.switcher {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
}
