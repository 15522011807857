.root {
  position: fixed;
}

@media (min-width: 600px) {
  .root {
    left: 335px;
    bottom: 15px;
  }
}

@media (max-width: 600px) {
  .root {
    right: 25px;
    bottom: 90px;
  }
}
