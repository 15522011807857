.arrowWrapper {
  position: absolute;
}

.arrowWrapper::after {
  content: '';
  position: absolute;
}

.arrowWrapper::before {
  content: '';
  position: absolute;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  overflow: hidden;
  height: 100%;
}

.backgroundImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
