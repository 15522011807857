.inputContainer {
  margin-bottom: 3px;
}

.helperTextContainer {
  margin-top: 0;
}

.boxContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
