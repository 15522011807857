.dot {
  top: 4px;
  right: 4px;
  background-color: #d50000;
}

.notificationIconContainer {
  margin-left: 16px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
}
.notificationIcon {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
}
