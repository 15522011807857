.root {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 412px;
  padding: 16px;
}

.rootFixed {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 412px;
  padding: 16px;
}

@media (max-width: 600px) {
  .root {
    width: 100%;
  }
  .rootFixed {
    width: 100%;
  }
}

.btn {
  padding: 6px;
}

.rightOffset {
  margin-right: 10px;
}
